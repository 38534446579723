@import "variables";

/******* Base styles *******/
* {
    margin : 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height     : 100%;
    font-family: $font-family;
    font-size  : 14px;
    line-height: 1.7;
}

.secondary-font {
    font-family: $secondary-font-family !important;
}

p {
    margin-bottom: 16px;
}

a {
    outline        : none;
    color          : inherit;
    text-decoration: unset;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.mw-100 {
    max-width: 100% !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.d-block {
    display: block !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.transition {
    -webkit-transition: 0.3s;
    -moz-transition   : 0.3s;
    -ms-transition    : 0.3s;
    transition        : 0.3s;
}

.text-truncate {
    white-space  : nowrap;
    text-overflow: ellipsis;
    overflow     : hidden !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.ws-nowrap {
    white-space: nowrap;
}

.o-hidden {
    overflow: hidden;
}

.transform-none {
    transform: none !important;
}

.flex-wrapper {
    margin: 0 -16px;
}

.m-auto {
    margin: 0 auto !important;
}

.pointer {
    cursor: pointer;
}

.full-desc {
    padding: 30px 40px;
}

.bg-cover {
    background-size: cover;
}

.theme-container {
    max-width : $theme-max-width;
    margin    : 0 auto;
    width     : 100%;
    box-sizing: border-box;
}

.app-menu-panel.mat-mdc-menu-panel {
    min-width: 60px;

    .mat-mdc-menu-content:not(:empty) {
        padding: 0;
    }

    .mat-mdc-menu-item {
        display       : flex;
        align-items   : center;
        font-size     : 12px;
        text-transform: uppercase;

        .mat-badge-small.mat-badge-above .mat-badge-content {
            top: 8px;
        }
    }

    &.lang {
        min-width: 41px;

        .mat-mdc-menu-item {
            line-height: 36px;
            height     : 36px;
            min-height : 36px;
            padding    : 0;

            img {
                display: block;
                margin : 0 auto;
            }

            .mdc-list-item__primary-text {
                width: 100%;
            }
        }
    }
}

.social-icon {
    display: inherit;
    color  : inherit;

    svg.mat-icon.mat-icon-lg {
        height: 37px;
    }
}

.lang-btn.mat-mdc-button {
    padding  : 0;
    min-width: 36px;
}

/******* Material Icons *******/
.mat-mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
    font-size     : 14px !important;
    line-height   : 14px !important;
    height        : 14px !important;
    width         : 14px !important;
    vertical-align: middle !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-sm {
    font-size     : 18px !important;
    line-height   : 18px !important;
    height        : 18px !important;
    width         : 18px !important;
    vertical-align: middle !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-md,
.mat-icon.mat-icon-md,
.mat-nav-list .mat-mdc-list-item .mat-mdc-list-item-icon.mat-icon-md {
    font-size     : 24px !important;
    line-height   : 24px !important;
    height        : 24px !important;
    width         : 24px !important;
    vertical-align: middle !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
    font-size     : 36px !important;
    line-height   : 36px !important;
    height        : 36px !important;
    width         : 36px !important;
    vertical-align: middle !important;
}

.mat-mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
    font-size     : 48px !important;
    line-height   : 48px !important;
    height        : 48px !important;
    width         : 48px !important;
    vertical-align: middle !important;
}

.mat-icon.caret {
    width   : 14px !important;
    overflow: visible;
    margin  : 0 !important;
}


.mat-mdc-snack-bar-container {
    button {
        font-size: 30px;
    }
}

.sidenav .mat-drawer-inner-container {
    overflow-x: hidden;
}

.horizontal-menu {
    .mat-mdc-button {
        padding       : 0 16px;
        text-transform: uppercase;
    }
}

.horizontal-sub-menu {
    .mat-mdc-button {
        display      : flex;
        place-content: flex-start;
        padding      : 0 16px;
    }

    .mat-mdc-button,
    .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
        text-transform: uppercase;
        font-weight   : 500;
    }
}


/******* Header *******/
#main-toolbar {
    position  : relative;
    height    : $top-toolbar-height;
    transition: 0.2s;
    z-index   : 9;
}

.top-toolbar.mat-toolbar {
    height   : $top-toolbar-height;
    font-size: 12px;

    .v-divider {
        width : 1px;
        height: 26px;
        margin: 0 16px;
    }

    .mat-mdc-button {
        font-size: 12px;
        padding  : 0 10px;
        color    : inherit;
    }
}

.logo {
    img {
        display  : block;
        width    : auto;
        max-width: 100%;
    }

    svg {
        display: block;
        width  :100%;
        height : auto;
    }
}

.user-menu {
    width: $user-menu-width;

    .user-info {
        padding: 16px;

        img {
            border-radius: 4px;
            margin-right : 16px;
        }
    }
}

.flag-name {
    margin-left: 4px;
}


header {
    &.toolbar-2 {
        .top-toolbar.mat-toolbar {
            height             : auto;
            position           : relative;
            background-repeat  : no-repeat;
            background-size    : cover;
            background-position: center;
            // background-image: url('~assets/images/others/top-bg.jpg'); 
            color              : #fff;

            .mat-toolbar-row:first-child {
                position: relative;
                height  : $top-toolbar-height;

                .right-section {
                    height: $top-toolbar-height;
                }
            }

            .mat-toolbar-row:nth-child(2) {
                position      : relative;
                height        : auto;
                font-size     : 14px;
                font-style    : italic;
                padding-top   : 8px;
                padding-bottom: $top-toolbar-height + 8px;

                .item {
                    width      : 100%;
                    white-space: normal;
                    line-height: 1.5;
                    padding    : 0 8px;
                }
            }

            &:before {
                content   : '';
                position  : absolute;
                top       : 0;
                width     : 100%;
                height    : 100%;
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &.has-bg-image,
    &.has-bg-video {
        #main-toolbar {
            box-shadow: none;
        }

        &.main-toolbar-fixed {
            #main-toolbar {
                box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            }
        }

        &:not(.main-toolbar-fixed) {
            #main-toolbar {
                background: transparent !important;
            }
        }
    }
}


/******* Theme dialog *******/
.theme-dialog {
    .header {
        padding: 8px 8px 8px 24px;
    }

    .mat-mdc-dialog-container {
        .mat-mdc-dialog-content {
            max-height: 95vh;
        }
    }

    .mat-mdc-dialog-actions {
        padding: 0 24px 16px;
    }

    &.fullscreen {
        width    : 100%;
        height   : 100%;
        max-width: 100% !important;
        transform: none !important;

        .mat-mdc-dialog-container {
            overflow: hidden;

            .mat-mdc-dialog-content {
                max-height: calc(100vh - 56px);
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .theme-dialog {
        max-height: 98vh !important;

        .header {
            padding: 8px;
        }

        .mat-mdc-dialog-container {
            .mat-mdc-dialog-content {
                max-height: 95vh;
                font-size : small;
            }
        }

        .mat-mdc-dialog-actions {
            padding: 0 16px 10px;
        }

        &.fullscreen {
            width    : 100%;
            height   : 100%;
            max-width: 100% !important;
            transform: none !important;

            .mat-mdc-dialog-container {
                overflow: hidden;

                .mat-mdc-dialog-content {
                    max-height: calc(100vh - 56px);
                }
            }
        }
    }
}

/******* Theme table *******/
.theme-table.mat-mdc-table {
    display   : block;
    overflow-x: auto;

    .mat-mdc-row,
    .mat-mdc-header-row {
        display            : flex;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        align-items        : center;
        min-height         : 48px;
        padding            : 0 24px;
        min-width          : 600px;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
        flex       : 1;
        overflow   : hidden;
        word-wrap  : break-word;
        padding    : 6px 8px;
        font-weight: 500;
    }

    .mat-mdc-header-cell {
        font-size: 14px;
    }

    .mat-mdc-cell,
    .mat-mdc-footer-cell {
        font-size: 14px;
    }

    .mat-mdc-cell {
        img {
            width  : 80px;
            display: block;
        }

        .grand-total {
            font-weight: 500;

            span:nth-child(3) {
                font-size  : 18px;
                white-space: nowrap;
            }
        }
    }

    &.truncated {

        .mat-mdc-cell,
        .mat-mdc-header-cell {
            white-space  : nowrap;
            text-overflow: ellipsis;
        }
    }

    &.header-sm {
        .mat-mdc-header-row {
            min-height: 40px;
        }
    }
}




/******* Pages *******/
.main-content-header {
    position  : relative;
    z-index   : 2;
    margin-top: 24px;
    padding   : 24px !important;
}

main.content-offset-to-top {
    .main-content-header {
        margin-top: -$main-content-header-offset-to-top;
    }
}


/******* APP *******/
.app {
    height: 100%;

    &.main-toolbar-fixed {
        #main-toolbar {
            position: fixed;
            top     : 0px;
            width   : 100%;
        }

        .page-wrapper {
            padding-top: $top-toolbar-height;
        }
    }
}


/******* All Menu Items *******/
.all-menu-items-wrapper {
    margin: 8px -8px;

    .item {
        padding: 8px;
    }
}

.all-menu-items {
    .search-sidenav {
        margin-bottom   : 16px;
        width           : $search-sidenav-width;
        border          : 0 !important;
        box-shadow      : none !important;
        background-color: transparent !important;

        .mat-drawer-inner-container {
            padding   : 2px;
            height    : 100%;
            box-sizing: border-box;

            .mat-mdc-card {
                height    : 100%;
                box-sizing: border-box;
            }
        }
    }

    .mat-drawer-content {
        min-height: 600px;
        padding   : 2px;
        overflow  : hidden;

        &.distance {
            padding-left: 16px;
        }
    }

    .mat-drawer-backdrop {
        bottom: 16px;
    }

    .mat-mdc-paginator-outer-container {
        font-size: 14px;
    }
}

.categories-toogle-group {
    &.mat-button-toggle-group {
        display    : inline-block;
        white-space: unset;
    }

    .mat-button-toggle {
        color  : inherit;
        display: inline-block;
    }
}


.table-wrapper {
    display   : block;
    overflow-x: auto;

    td.mat-mdc-cell,
    th.mat-mdc-header-cell {
        white-space: nowrap;
        padding    : 16px;
    }

    a.menu-item-name {
        color          : inherit;
        text-decoration: none;
        font-weight    : 500;
    }
}

.mat-mdc-form-field.m-0 {

    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        margin: 0 !important;
    }
}

.mat-mdc-form-field.p-0 {
    height: 56px;
}


/******* Comments *******/
.mat-mdc-list.reviews {
    .mdc-list-item {
        margin-bottom: 24px;

        &__content {
            white-space: unset;
            overflow   : unset;

            p {
                margin-bottom: 0;

                &.text {
                    white-space: unset;
                    font-style : italic;
                    margin-top : 8px;
                }
            }
        }

        &__primary-text {
            white-space: unset;
        }
    }

    .mat-mdc-list-item .mat-mdc-list-item-avatar.review-author {
        width     : 80px;
        height    : 80px;
        align-self: flex-start;
    }
}

.comment-form {
    margin : -16px;
    padding: 8px;
}


/******* Account *******/
.account {
    .mat-drawer {
        width           : 280px;
        border          : 0 !important;
        box-shadow      : none !important;
        background-color: transparent !important;

        .mat-drawer-inner-container {
            padding   : 2px;
            height    : 100%;
            box-sizing: border-box;

            .mat-mdc-card {
                height    : 100%;
                box-sizing: border-box;
                overflow  : hidden;

                a {
                    color          : inherit;
                    text-decoration: none;
                }
            }

            button.close {
                position: absolute;
                top     : 2px;
                right   : 2px;
                z-index : 1;
            }
        }
    }

    .mat-drawer-content {
        padding   : 2px;
        min-height: 560px;

        &.distance {
            padding-left: 16px;
        }
    }
}


/******* Cart *******/
.mat-icon.empty-cart-icon {
    font-size     : 150px;
    line-height   : 150px;
    height        : 150px;
    width         : 150px;
    vertical-align: middle;
}

.theme-table.mat-mdc-table.cart-table {
    .mat-mdc-row {
        min-height: 80px;
    }

    .mat-mdc-row,
    .mat-mdc-header-row {
        min-width: 870px;
    }

    .mat-mdc-cell,
    .mat-mdc-footer-cell {
        font-size: 16px;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
        &.image {
            flex-grow  : 0;
            flex-shrink: 0;
            flex-basis : 170px;
        }

        &.total {
            flex-grow  : 0;
            flex-shrink: 0;
            flex-basis : 130px;
        }

        &.actions {
            flex-grow  : 0;
            flex-shrink: 0;
            flex-basis : 184px;
        }
    }
}



/******* Checkout *******/
.checkout {
    .mat-drawer {
        width           : 320px;
        border          : 0 !important;
        box-shadow      : none !important;
        background-color: transparent !important;

        .mat-drawer-inner-container {
            padding   : 2px;
            height    : 100%;
            box-sizing: border-box;

            .mat-mdc-card {
                height    : 100%;
                box-sizing: border-box;
                overflow  : hidden;
            }

            button.close {
                position: absolute;
                top     : 2px;
                right   : 2px;
                z-index : 1;
            }
        }
    }

    .mat-drawer-content {
        padding   : 2px;
        min-height: 560px;

        &.distance {
            padding-right: 16px;
        }
    }
}

.theme-table.mat-mdc-table.review-order-table {
    .mat-mdc-row {
        min-height: 80px;
    }

    .mat-mdc-row,
    .mat-mdc-header-row {
        min-width: 600px;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
        &.image {
            flex-grow  : 0;
            flex-shrink: 0;
            flex-basis : 80px;

            img {
                width: 60px;
            }
        }

        &.total {
            flex-grow  : 0;
            flex-shrink: 0;
            flex-basis : 70px;
        }
    }
}




/******* Footer *******/
footer {
    position  : relative;
    overflow  : hidden;
    background: #fafafa;
    color: #1c1c1c;

    &:before {
        content            : '';
        position           : absolute;
        top                : 0;
        z-index            : -1;
        width              : 100%;
        height             : 100%;
        // background-image   : url('~assets/images/others/footer.jpg');
        background-repeat  : no-repeat;
        background-size    : cover;
        background-position: center;
    }

    .content {
        border-width: 0px 0px 1px 0px;
        border-style: solid;

        .logo svg {
            fill : #fff;
            width: 260px;
        }

        .desc {
            color: rgba(0, 0, 0, 0.7);
        }

        .custom-form {
            .mdc-text-field:not(.mdc-text-field--disabled) {
                .mdc-text-field__input {
                    color: #000000;

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }

            .mdc-text-field:not(.mdc-text-field--disabled, .mdc-text-field--invalid) {
                .mdc-floating-label {
                    color: rgba(255, 255, 255, 0.7);
                }
            }

            .mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline__leading,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline__notch,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid) .mdc-notched-outline__trailing {
                border-color: rgba(255, 255, 255, 0.3);
            }

            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
            .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
                border-color: #fff;
            }

            .subscribe-input {
                height: 56px;
            }

            .subscribe-btn {
                margin-left   : -4px;
                border-radius : 0 4px 4px 0;
                text-transform: uppercase;
                height        : auto;
            }
        }
    }

    .copyright {
        color    : rgba(23, 23, 23, 0.956);
        font-size: 12px;

        p {
            margin: 0;
        }
    }

}


/******* Carousel *******/
button.swipe-arrow {
    position        : absolute;
    top             : 50%;
    margin-top      : -22px;
    z-index         : 10;
    background-image: none !important;

    &.transparent {
        background: transparent !important;
        box-shadow: none;
    }
}

.swiper-pagination.white .swiper-pagination-bullet {
    background: #fff;
}

.swiper-pagination-bullet-active {
    width         : 10px;
    height        : 10px;
    vertical-align: -1px;
}

.carousel-outer {
    margin : -2px;
    padding: 2px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: none;
}

.menu-items-carousel .swiper-wrapper .swiper-slide {
    height       : auto;
    margin-bottom: 32px;
}


/******* Section *******/
.section {
    position     : relative;
    overflow     : hidden;
    // margin-top: 40px;
   

    &:before {
        content            : '';
        position           : absolute;
        top                : 0;
        z-index            : -1;
        width              : 100%;
        height             : 100%;
        background-repeat  : no-repeat;
        background-size    : cover;
        background-position: center;
        opacity            : 0.15;
    }

    // &.testimonials{
    //     &:before{ 
    //         background-image: url('~assets/images/others/testimonials.jpg');
    //     }
    // } 
    // &.chefs{
    //     &:before{ 
    //        background-image: url('~assets/images/chefs/chefs.jpg');
    //     }
    // } 
    &.default {
        &:before {
            background-repeat  : repeat;
            background-size    : 350px;
            background-position: center;
            opacity            : 1;
        }
    }

    .section-title {
        text-align   : center;
        margin-bottom: 12px;
        position     : relative;
        font-size    : 30px;
        font-family  : $secondary-font-family;

        &:after {
            content  : '';
            display  : inline-block;
            position : absolute;
            bottom   : 0;
            left     : 0;
            right    : 0;
            max-width: 160px;
            margin   : 0 auto;
            height   : 3px;
        }
    }

    .section-desc {
        margin       : 0 auto;
        text-align   : center;
        max-width    : 50%;
        font-size    : 15px;
        font-weight  : 500;
        margin-bottom: 32px;
    }
}


/******* Landing Page *******/
.landing-page .logo svg {
    fill: #fff;
}


/******* Frame box *******/
.frame-box {
    width     : 100%;
    padding   : 48px 0;
    box-sizing: border-box;

    .frame-image {
        background-repeat  : no-repeat;
        background-size    : cover;
        background-position: center;
        min-height         : 500px;
        height             : 100%;
        position           : relative;

        .top-mask:before {
            content : "";
            height  : 50px;
            width   : 40%;
            position: absolute;
            left    : 0;
        }

        .top-mask:after {
            content   : "";
            height    : 35px;
            width     : 57.3%;
            width     : calc(60% - 30px);
            position  : absolute;
            right     : 15px;
            margin-top: 15px;
        }

        .bottom-mask:before {
            content : "";
            height  : 50px;
            width   : 40%;
            position: absolute;
            bottom  : 0;
            left    : 0;
        }

        .bottom-mask:after {
            content      : "";
            height       : 35px;
            width        : 57.3%;
            width        : calc(60% - 30px);
            position     : absolute;
            right        : 15px;
            bottom       : 0;
            margin-bottom: 15px;
        }

        .content-wrapper {
            width  : 100%;
            display: inline-block;
            height : 100%;

            .content {
                float         : right;
                width         : 600px;
                height        : 100%;
                min-height    : 410px;
                padding       : 30px;
                vertical-align: middle;
                margin        : 15px 15px;
                position      : relative;
                box-sizing    : border-box;
            }
        }
    }

    &:nth-child(even) {
        padding: 48px 0;

        .frame-image {
            .top-mask:before {
                right: 0;
                left : auto;
            }

            .top-mask:after {
                left: 15px;
            }

            .bottom-mask:before {
                right: 0;
                left : auto;
            }

            .bottom-mask:after {
                left: 15px;
            }

            .content-wrapper {
                .content {
                    float: left;
                }
            }
        }
    }

    &:last-child {
        padding-bottom: 0;
    }
}

/******* Cart Items bottomSheet *******/
.mat-mdc-list.cart-items.mat-mdc-list-base {
    padding: 0;

    .mat-mdc-list-item {
        height : auto;
        padding: 0;

        .mdc-list-item__primary-text {
            white-space: normal;

            a.menu-item-name {
                padding: 0 8px;
            }
        }
    }
}


/******* Menu Single *******/
.menu-single {
    min-height: calc(100vh - (#{$top-toolbar-height} + #{$main-toolbar-height}));

    .mat-mdc-chip-listbox {
        position      : absolute;
        top           : 24px;
        left          : 24px;
        pointer-events: none;
    }
}

.old-price {
    text-decoration: line-through;
}



/******* Reservation form *******/
.reservation-form {
    padding: 36px 24px 24px;
}

.reservation-form-footer {
    width      : 100%;
    text-align : center;
    padding    : 24px;
    box-sizing : border-box;
    font-size  : 16px;
    font-weight: 500;
}


/******* Responsive *******/
// xs
@media screen and (max-width: 599px) {
    .mat-mdc-list.cart-items.mat-mdc-list-base {
        .mat-mdc-list-item {
            .mdc-list-item__primary-text {
                a.menu-item-name {
                    padding: 0;
                }
            }
        }
    }
}

// sm
@media screen and (min-width: 600px) and (max-width: 959px) {}

@media screen and (max-width: 959px) {
    .all-menu-items {
        .search-sidenav {
            float   : none;
            position: absolute;

            .mat-drawer-inner-container {
                padding: 0px;

                .mat-mdc-card {
                    box-shadow   : none;
                    border-radius: 0;
                }
            }
        }
    }

    .account,
    .checkout {
        .mat-drawer {
            .mat-drawer-inner-container {
                padding: 0px;

                .mat-mdc-card {
                    box-shadow   : none;
                    border-radius: 0;
                }
            }
        }
    }


    .section {
        .section-title {
            font-size: 26px;
        }

        .section-desc {
            max-width: 90%;
            font-size: 14px;
        }
    }

    .frame-box {
        .frame-image {
            background-size    : contain;
            background-position: top center;

            .top-mask:before,
            .top-mask:after,
            .bottom-mask:before,
            .bottom-mask:after {
                display: none;
            }

            .content-wrapper {
                .content {
                    float     : none;
                    width     : 90%;
                    min-height: 350px;
                    margin    : 15px auto;
                    margin-top: 45%;
                }
            }
        }

        &:nth-child(even) {
            .frame-image {
                .content-wrapper {
                    .content {
                        float: none;
                    }
                }
            }
        }
    }

    .reservation-form {
        padding: 0 0 24px 0;
        border : none !important;
        margin : 0 -16px;
    }
}

// md
@media screen and (min-width: 960px) and (max-width: 1279px) {
    .frame-box {
        .frame-image {
            .top-mask:before {
                width: 30%;
            }

            .top-mask:after {
                width: calc(70% - 30px);
            }

            .bottom-mask:before {
                width: 30%;
            }

            .bottom-mask:after {
                width: calc(70% - 30px);
            }

            .content-wrapper {
                .content {
                    float     : right;
                    width     : 550px;
                    min-height: 410px;
                }
            }
        }
    }
}

// lg
@media screen and (min-width: 1280px) and (max-width: 1919px) {}

// xlg
@media screen and (min-width: 1920px) and (max-width: 5000px) {}



::-webkit-scrollbar {
    width : 0px;
    height: 0px;
}

::-webkit-scrollbar-button {
    width : 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background   : #e1e1e1;
    border       : 0px none #ffffff;
    border-radius: 0px;

    &:hover {
        background: #cccccc;
    }

    &:active {
        background: #888888;
    }
}

::-webkit-scrollbar-track {
    background   : #666666;
    border       : 0px none #ffffff;
    border-radius: 0px;

    &:hover {
        background: #666666;
    }

    &:active {
        background: #333333;
    }
}

::-webkit-scrollbar-corner {
    background: transparent;
}


//----------ng-scrollbar---------------
ng-scrollbar {
    --scrollbar-size             :0px !important;
    --scrollbar-thumb-color      : rgba(204, 204, 204, 0.8) !important;
    --scrollbar-thumb-hover-color: #ccc !important;
    --scrollbar-border-radius    : 0px !important;
    --scrollbar-hover-size       : 0px !important;
    --scrollbar-padding          : 0px !important;
}