.toolbar-dropdown-menu{
    margin-top: 9px;
    border-radius: 0 !important;
    .mat-mdc-menu-content{
        padding: 0 !important;
    }
}

.admin-user-menu{
    .mat-toolbar, .mat-toolbar-row{
        height: 100px !important;
    }
    .user-info{
        width: 230px;
        p{
            font-size: 16px;
            line-height: 22px;
            text-align: center;
        }
        img{
            border-radius: 50%;
        }
    }
    .mat-mdc-menu-item{
        height: 36px;
        line-height: 36px;
    }
}

.h-300p{
    height: 300px;
}
.h-400p{
    height: 400px;
}

.uppercase{
    text-transform: uppercase;
}

.admin-toolbar .logo svg{ 
    fill: #fff;
    width: 75px; 
    height: auto;  
}

.user-block{
    height: 0;
    position: relative;  
    z-index: 1; 
    overflow: hidden; 
    transition: 0.2s; 
    img{
        width: 80px; 
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 50%;       
    }
    .user-info-wrapper{
        padding: 0 6px;
        margin: 6px 0;
        width: 100%;
        .user-info{
            text-align: center;
            .name{
                font-size: 15px;
                margin-bottom: 0;
                font-weight: 500;
            }
            .position{
                font-size: 13px;
                margin-bottom: 0;
            }
        }
    } 
    &.show{
        height: $sidenav-user-block-height;
    }      
}

.vertical-tabs.mat-mdc-tab-group{
    flex-direction: row; 
    .mat-mdc-tab-labels{
        flex-direction: column;
    }
    .mdc-tab-indicator__content--underline {
        display: none;
    }
    .mat-mdc-tab-body-wrapper{
        padding-left: 16px;
    }
}
.tab-content-wrapped.mat-mdc-tab-group .mat-mdc-tab-body-content {
    overflow: hidden;
}