@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css"; 
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base"; 
@import "app/theme/styles/admin";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl"; 

@keyframes foo { 
	0% { content: url(logo-gif.gif); } 
	25% { content: url(logo-gif.gif); } 
	50% { content: url(logo-gif.gif); } 
	75% { content: url(logo-gif.gif); } 
	100% { content: url(logo-gif.gif); } 
} 
 
.bar { 
	animation-name: foo; 
	animation-duration: 3s ; 
	animation-iteration-count: infinite; 
    width: 100px;
} 
*::-webkit-scrollbar {
    width: 0px !important;
    background-color: white !important;
  }
  
  *::-webkit-scrollbar-thumb {
    width: 0px !important;
    background-color: whitesmoke !important;
    border-radius: 0px !important;
  }
  
  *::-webkit-scrollbar:horizontal {
    width: 0px !important;
    background-color: white !important;
  }
  
  *::-webkit-scrollbar-thumb:horizontal {
    width: 0px !important;
    background-color: whitesmoke !important;
    border-radius: 0px;
  }